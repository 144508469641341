// AuthLayout.tsx
import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const AuthLayout: React.FC = () => {
  const a = Boolean(localStorage.getItem("accessToken"));
  if (!a) {
    console.log("AuthLayout: isAuthenticated is false");

    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default AuthLayout;
