import DynamicTable from "@/components/DynamicTable";
import { Input, InputNumber } from "antd";
import React from "react";

const price: React.FC = () => {
  const columns = [
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "金额(元)",
      dataIndex: "price",
    },
    {
      title: "描述",
      dataIndex: "description",
    },
  ];

  const formItems = [
    {
      name: "title",
      label: "标题",
      rules: [{ required: true, message: "请输入标题!" }],
      component: <Input />,
    },
    {
      name: "price",
      label: "金额（元）",
      rules: [{ required: true, message: "请输入金额" }],
      component: <InputNumber addonAfter="元" />,
    },
    {
      name: "description",
      label: "描述",
      component: <Input />,
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      dataSourceUrl="/price"
      createUrl="/price"
      updateUrl="/price"
      deleteUrl="/price"
      formItems={formItems}
      title="价格管理"
    />
  );
};

export default price;
