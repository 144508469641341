import { API_URL } from "../config.ts";
import axios from "axios";

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    timeout: 10000, // 请求超时设置为10秒
  });

  // set token
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken") || "";
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // 响应拦截器
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          console.log(error.response);
          originalRequest._retry = true;
          const refreshToken = localStorage.getItem("refreshToken");
          console.log("refreshToken", refreshToken);
          const response = await axios.post(baseURL + "/wx/token", {
            token: refreshToken,
          });
          const newAccessToken = response.data.accessToken;
          // 更新本地存储的访问 token
          localStorage.setItem("accessToken", newAccessToken);
          // 重新发送原始请求
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return instance(originalRequest);
        } catch (refreshError) {
          // 刷新 token 失败
          if (
            // @ts-ignore
            refreshError.response.status === 403 &&
            // @ts-ignore
            refreshError.response.data.error === "refresh_token_expired"
          ) {
            // 引导用户重新登录
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            setTimeout(() => {
              if (window.location.pathname.includes("web")) {
                window.location.href = "/web-login";
              } else {
                window.location.href = "/login";
              }
            }, 1000);
          }
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

// 创建不同后端服务的 Axios 实例
const client = createAxiosInstance(API_URL);

const fetcher = (url: string) => client.get(url).then((res) => res.data);

export { client, fetcher };
