import DynamicTable from "@/components/DynamicTable";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import React from "react";

const onChange = (key: string) => {
  console.log(key);
};

const payment_status = {
  PAID: "已支付",
};

const refund_status = {
  approving: "申请退款",
  agree: "已退款",
  reject: "已拒绝",
};

const columns = [
  {
    title: "付款状态",
    dataIndex: "status",
    render: (status: string) => <span>{payment_status[status]}</span>,
  },
  {
    title: "退款状态",
    dataIndex: "refund_status",
    render: (status: string) => <span>{refund_status[status]}</span>,
  },
  {
    title: "发布工作",
    dataIndex: ["product_order", "product", "title"],
    render: (object_type: string) => (
      <span>{object_type === "post" ? "帖子" : "订单"}</span>
    ),
  },
  {
    title: "接单",
    dataIndex: ["promise_order", "products", "description"],
  },
  {
    title: "付款",
    dataIndex: ["promise_order", "price"],
    render: (price: number) => <span>{price}元</span>,
  },
  {
    title: "退款",
    dataIndex: ["promise_order", "refund"],
    render: (price: number) => <span>{price}元</span>,
  },
  {
    title: "用户",
    dataIndex: ["promise_order", "users", "nickname"],
  },
];

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "发帖",
    children: (
      <DynamicTable
        columns={columns}
        dataSourceUrl="/payment_records/publish"
        createUrl="/payment_records"
        updateUrl="/payment_records"
        deleteUrl="/payment_records"
        title="付款记录"
        readonly
      />
    ),
  },
  {
    key: "2",
    label: "接单",
    children: (
      <DynamicTable
        columns={columns}
        dataSourceUrl="/payment_records/promise"
        createUrl="/payment_records"
        updateUrl="/payment_records"
        deleteUrl="/payment_records"
        title="付款记录"
        readonly
      />
    ),
  },
];

const App: React.FC = () => (
  <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
);

export default App;
