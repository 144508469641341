import ProductCategories from "./manage-product-category";
import ManageSwiperImages from "./manage-swiper-images";
import { SUPPORT_PROMOTIONS } from "@/config";
import CategoryGroup from "@/router/category_group";
import MamageProduct from "@/router/manage-product";
import ManagePromotions from "@/router/manage-promotions";
import payment_records from "@/router/payment_records";
import Price from "@/router/price";
import refund from "@/router/refund";

export const routerInfo = [
  {
    path: "/manage-swiper-images",
    title: "轮播图",
    Component: ManageSwiperImages,
  },
  {
    path: "/category_group",
    title: "工作类型分组",
    Component: CategoryGroup,
  },
  // {
  //   path: "/manage-product",
  //   title: `管理${PRODUCT_TITLE}`,
  //   Component: MamageProduct,
  // },
  {
    path: "/manage-product-category",
    title: `工作类型`,
    Component: ProductCategories,
  },

  {
    path: "/manage-product",
    title: "用户发布的工作",
    Component: MamageProduct,
  },
  {
    path: "/refund",
    title: "退款申请",
    Component: refund,
  },
  {
    path: "/payment_records",
    title: "付款记录",
    Component: payment_records,
  },
  {
    path: "/price",
    title: "价格设置",
    Component: Price,
  },

  // {
  //   path: "/manage-vouchers",
  //   title: "管理优惠券",
  //   Component: ManageVouchers,
  // },
  // {
  //   path: "/manage-membership-plans",
  //   title: "管理会员计划",
  //   Component: MembershipPlansPage,
  // },
  // {
  //   path: "/manage-membership-plan-vouchers",
  //   title: "管理会员赠送优惠券",
  //   Component: ManageMembershipPlanVouchers,
  // },
  // {
  //   path: "/manage-product",
  //   title: "查看会员销售记录",
  //   Component: MamageProduct,
  // },
];

if (SUPPORT_PROMOTIONS === "true") {
  routerInfo.push(
    {
      path: "/manage-promotions",
      title: "管理推广套餐",
      Component: ManagePromotions,
    },
    // TODO
    {
      path: "/manage-product",
      title: "查看推广销售记录",
      Component: MamageProduct,
    },
  );
}
