import { client } from "@/api/axios";
import { useEffect, useState } from "react";

const useOptions = (url: string, labelHandler: (item: any) => string) => {
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    [],
  );

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await client.get(url);
        setOptions(
          (response.data.data || response.data).map(
            (item: {
              username: string;
              plan_name: string;
              title: string;
              id: number;
              name: string;
            }) => ({
              label: labelHandler
                ? labelHandler(item)
                : item.name || item.title || item.plan_name || item.username,
              value: item.id,
            }),
          ),
        );
      } catch (error) {
        console.error("Failed to fetch options", error);
      }
    };

    fetchOptions();
  }, [url]);

  return options;
};

export default useOptions;
