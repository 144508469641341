import DynamicTable from "@/components/DynamicTable";
import MyUpload from "@/components/upload";
import useOptions from "@/hooks/useOptions";
import { imageUrl } from "@/utils/image-url";
import { Input, Select } from "antd";
import React from "react";

export interface ProductCategory {
  id: number;
  name: string;
  icon_url: string;
  icon_media: number;
  sort: number;
}

const ManagePromotions: React.FC = () => {
  // 上传文件变化处理
  const handleUploadChange = (info: any) => {
    console.log(0, info);
  };

  const columns = [
    {
      title: "排序",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "分组",
      key: "group",
      dataIndex: ["category_group", "title"],
    },
    {
      title: "接单保证金(元)",
      key: "group",
      dataIndex: ["price_product_categories_publish_price_idToprice", "price"],
    },
    {
      title: "接单保证金-退款(元)",
      key: "group",
      dataIndex: ["price_product_categories_refund_price_idToprice", "price"],
    },
    {
      title: "上传图标",
      dataIndex: ["media", "url"],
      key: "icon_media",
      render: (iconMedia: string) => (
        <img
          src={
            iconMedia ? imageUrl(iconMedia) : "https://via.placeholder.com/50"
          }
          alt="icon"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
  ];

  const prices = useOptions("/price", (item) => {
    return `${item.title}(${item.price}元)`;
  }); // 代金券列表

  console.log(prices);

  const formItems = () => [
    {
      name: "name",
      label: "名称",
      rules: [{ required: true, message: "请输入名称!" }],
      component: <Input />,
    },
    {
      name: "publish_price_id",
      label: `接单保证金`,
      component: (
        <Select placeholder={`选择价格`} style={{ width: "100%" }}>
          {prices.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "refund_price_id",
      label: `接单保证金-退款`,
      component: (
        <Select placeholder={`选择价格`} style={{ width: "100%" }}>
          {prices.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "icon_media",
      label: "上传图标图片",
      render: (value: any) => (
        <MyUpload medias={[value?.media]} onChange={handleUploadChange} />
      ),
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      dataSourceUrl="/product-categories"
      createUrl="/product-categories"
      updateUrl="/product-categories/media"
      deleteUrl="/product-categories"
      formItems={formItems()}
      title="分类"
      sortable={true}
    />
  );
};

export default ManagePromotions;
