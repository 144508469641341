import DynamicTable from "@/components/DynamicTable";
import MediaPreview from "@/components/MediaPreview";
import MyUpload from "@/components/upload";
import { Input } from "antd";
import React from "react";

const ManageProducts: React.FC = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "分类",
      dataIndex: ["product_categories", "name"],
      key: "category_id",
    },
    {
      title: "轮播图ID",
      dataIndex: "swiper_id",
      key: "swiper_id",
    },
    {
      title: "发布者",
      dataIndex: ["users", "username"],
      key: "publisher_id",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "价格",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "真实价格",
      dataIndex: "real_price",
      key: "real_price",
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      render: (description: string) => (
        <p className="line-clamp-2 max-w-28" title={description}>
          {description}
        </p>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt: string) => (
        <span>{new Date(createdAt).toLocaleString()}</span>
      ),
    },
    {
      title: "图片",
      dataIndex: "product_media",
      key: "product_media",
      render: (productMedia: any[]) => (
        <MediaPreview urls={productMedia.map((media) => media.media.url)} />
      ),
    },
  ];

  const formItems = [
    {
      name: "title",
      label: "标题",
      rules: [{ required: true, message: "请输入标题!" }],
      component: <Input />,
    },
    {
      name: "category_id",
      label: "分类ID",
      component: <Input />,
    },
    {
      name: "swiper_id",
      label: "轮播图ID",
      component: <Input />,
    },
    {
      name: "publisher_id",
      label: "发布者ID",
      component: <Input />,
    },
    {
      name: "status",
      label: "状态",
      component: <Input />,
    },
    {
      name: "price",
      label: "价格",
      component: <Input />,
    },
    {
      name: "real_price",
      label: "真实价格",
      component: <Input />,
    },
    {
      name: "description",
      label: "描述",
      component: <Input.TextArea />,
    },
    {
      name: "product_media",
      label: "媒体",
      render: (value: any) => (
        <MyUpload medias={value?.product_media.map((i: any) => i.media)} />
      ),
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      dataSourceUrl="/products"
      createUrl="/products"
      updateUrl="/products"
      deleteUrl="/products"
      formItems={formItems}
      title="产品"
      sortable={false}
    />
  );
};

export default ManageProducts;
