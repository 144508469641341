import DynamicTable from "@/components/DynamicTable";
import { Input } from "antd";
import React from "react";

const category_group: React.FC = () => {
  const columns = [
    {
      title: "标题",
      dataIndex: "title",
    },
  ];

  const formItems = [
    {
      name: "title",
      label: "标题",
      rules: [{ required: true, message: "请输入标题!" }],
      component: <Input />,
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      dataSourceUrl="/category_group"
      createUrl="/category_group"
      updateUrl="/category_group"
      deleteUrl="/category_group"
      formItems={formItems}
      title="工作类型"
    />
  );
};

export default category_group;
