import { client } from "@/api/axios";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Button,
  Form,
  Modal,
  Table,
  Popconfirm,
  message,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";

interface DynamicTableProps {
  columns: any[];
  dataSourceUrl: string;
  createUrl: string;
  updateUrl: string;
  deleteUrl: string;
  formItems?: any[];
  title: string;
  sortable?: boolean; // 新增的属性，用于控制是否开启排序功能
  pageSize?: number; // 每页显示的条数
  readonly?: boolean; // 新增的属性，用于控制是否只读
  customeActions?: any[];
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const DynamicTable: React.FC<DynamicTableProps> = ({
  columns,
  dataSourceUrl,
  createUrl,
  updateUrl,
  deleteUrl,
  formItems,
  title,
  sortable = false, // 默认不启用排序功能
  pageSize = 10, // 默认每页显示10条数据
  readonly = false,
  customeActions = [],
}) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const fetchData = async () => {
    try {
      const response = await client.get(dataSourceUrl, {
        params: {
          page: currentPage,
          pageSize,
        },
      });
      setDataSource(response.data.data || response.data);
      setTotal(response.data.total);
    } catch (error) {
      message.error("数据加载失败");
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await client.delete(`${deleteUrl}/${id}`);
      fetchData();
      message.success("删除成功");
    } catch (error) {
      message.error("删除失败");
    }
  };

  const handleCreate = async (values: any) => {
    try {
      await client.post(createUrl, values);
      fetchData();
      setVisible(false);
      message.success("新增成功");
    } catch (error) {
      message.error("新增失败");
    }
  };

  const handleUpdate = async (values: any) => {
    if (!editingItem) {
      return;
    }
    try {
      await client.put(`${updateUrl}/${editingItem.id}`, values);
      fetchData();
      setEditingItem(null);
      setVisible(false);
      message.success("更新成功");
    } catch (error) {
      message.error("更新失败");
    }
  };

  const handleOrder = async (values: any) => {
    try {
      const updatedOrderData = values.map((item: { id: any; sort: any }) => ({
        id: item.id,
        order: item.sort,
      }));
      await client.put(`${updateUrl}/order`, updatedOrderData);

      setDataSource(values);
      message.success("更新成功");
    } catch (error) {
      message.error("更新失败");
    }
  };

  const handleSubmit = (values: any) => {
    if (editingItem) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        const newDataSource = arrayMove(prev, activeIndex, overIndex).map(
          (category, index) => ({
            ...category,
            sort: index + 1,
          }),
        );
        handleOrder(newDataSource);
        return newDataSource;
      });
    }
  };

  const action = {
    title: "操作",
    render: (_: any, record: any) => (
      <>
        <Button
          onClick={() => {
            setEditingItem(record);
            form.setFieldsValue(record);
            setVisible(true);
          }}
        >
          编辑
        </Button>
        <Popconfirm title="确定删除?" onConfirm={() => handleDelete(record.id)}>
          <Button style={{ marginLeft: 8 }}>删除</Button>
        </Popconfirm>
      </>
    ),
  };

  const customeActions_ =
    customeActions.length > 0
      ? {
          title: "操作",
          dataIndex: "action",
          render: (_: any, record: any) => (
            <div>
              {customeActions.map((item) => (
                <Button
                  key={item.title}
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    item.onClick(record);
                  }}
                >
                  {item.title}
                </Button>
              ))}
            </div>
          ),
        }
      : undefined;

  return (
    <>
      {formItems && (
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
          style={{ marginBottom: 16 }}
        >
          新增{title}
        </Button>
      )}
      <FormModal
        title={title}
        form={form}
        formItems={formItems}
        handleSubmit={handleSubmit}
        visible={visible}
        setVisible={setVisible}
        editingItem={editingItem}
        setEditingItem={setEditingItem}
      />
      <Sortable
        sensors={sensors}
        onDragEnd={onDragEnd}
        dataSource={dataSource}
        sortable={sortable}
      >
        <Table
          components={
            sortable
              ? {
                  body: {
                    row: Row,
                  },
                }
              : undefined
          }
          rowKey="id"
          columns={[
            ...columns,
            readonly ? undefined : action,
            customeActions_,
          ].filter((i) => !!i)}
          dataSource={dataSource}
          pagination={false}
        />
      </Sortable>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: 16, textAlign: "right" }}
      />
    </>
  );
};

export default DynamicTable;

function Sortable({ children, sensors, onDragEnd, dataSource, sortable }: any) {
  if (sortable !== true) {
    return children;
  }

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={dataSource.map((i: any) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
}

function FormModal({
  visible,
  editingItem,
  title,
  setVisible,
  setEditingItem,
  form,
  handleSubmit,
  formItems,
}: any) {
  return (
    <Modal
      open={visible}
      title={editingItem ? `编辑${title}` : `新增${title}`}
      okText={editingItem ? "更新" : "创建"}
      cancelText="取消"
      onCancel={() => {
        setVisible(false);
        setEditingItem(null);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values: any) => {
            console.log(121, values);
            if (!values.id && editingItem) {
              values.id = editingItem.id;
            }
            handleSubmit(values);
            form.resetFields();
          })
          .catch((info: any) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name={`${title.toLowerCase()}_form`}
        initialValues={editingItem || {}}
      >
        {formItems &&
          formItems.map((item: any) => (
            <Form.Item
              key={item.name}
              name={item.name}
              label={item.label}
              rules={item.rules}
            >
              {item.render ? item.render(editingItem) : item.component}
            </Form.Item>
          ))}
      </Form>
    </Modal>
  );
}
