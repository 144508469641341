// src/config.ts
export const API_URL = import.meta.env.VITE_API_URL;
export const UI_URL = import.meta.env.VITE_UI_URL;
export const DEFAULT_reward_coins = 10;

export const ANOTHER_ENV_VAR = import.meta.env.VITE_ANOTHER_ENV_VAR;

export const TITLE = "系统管理后台";
export const PRODUCT_TITLE = import.meta.env.VITE_PRODUCT_TITLE;
export const PRODUCT_CATEGORY_TITLE = import.meta.env
  .VITE_PRODUCT_CATEGORY_TITLE;

// 是否支持推广
export const SUPPORT_PROMOTIONS = import.meta.env.VITE_SUPPORT_PROMOTIONS;
