import DynamicTable from "@/components/DynamicTable";
import { Input, InputNumber } from "antd";
import React from "react";

const ManagePromotions: React.FC = () => {
  const columns = [
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "描述",
      dataIndex: "description",
    },
    {
      title: "价格",
      dataIndex: "price",
    },
    {
      title: "曝光次数",
      dataIndex: "exposure_count",
    },
    {
      title: "真实价格",
      dataIndex: "real_price",
    },
  ];

  const formItems = [
    {
      name: "title",
      label: "标题",
      rules: [{ required: true, message: "请输入标题!" }],
      component: <Input />,
    },
    {
      name: "description",
      label: "描述",
      component: <Input />,
    },
    {
      name: "price",
      label: "价格",
      rules: [{ required: true, message: "请输入价格!" }],
      component: <InputNumber min={0} style={{ width: "100%" }} />,
    },
    {
      name: "exposure_count",
      label: "曝光次数",
      rules: [{ required: true, message: "请输入曝光次数!" }],
      component: <InputNumber min={0} style={{ width: "100%" }} />,
    },
    {
      name: "real_price",
      label: "真实价格",
      component: <InputNumber min={0} style={{ width: "100%" }} />,
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      dataSourceUrl="/promotions"
      createUrl="/promotions"
      updateUrl="/promotions"
      deleteUrl="/promotions"
      formItems={formItems}
      title="推广套餐"
    />
  );
};

export default ManagePromotions;
