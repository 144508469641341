import "./App.css";
import AuthLayout from "./components/AuthLayout";
import { TITLE } from "./config";
import { AuthProvider } from "./provider/AuthProvider";
import { routerInfo } from "./router/router-info";
import Web from "./router/web";
import WebLogin from "./router/web-login";
import ErrorPage from "@/router/error-page.tsx";
import MamageProduct from "@/router/manage-product";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/login",
    Component: WebLogin,
  },
  // 需要权限的路由全部放在children中
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        Component: Web,
        children: [
          {
            index: true,
            path: "/",
            Component: MamageProduct,
          },
          ...routerInfo,
        ],
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
