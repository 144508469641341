import WebNav from "@/components/web-nav";
import React from "react";
import { Outlet } from "react-router-dom";

const Web: React.FC = () => {
  return (
    <div className={"flex h-screen"}>
      <WebNav />
      <div
        className={
          "container mx-auto h-full flex-1 overflow-y-auto border-t pt-5"
        }
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Web;
