import { imageUrl } from "@/utils/image-url";
import { Button, Modal, Image } from "antd";
import React, { useState } from "react";

export interface MediaPreviewProps {
  urls: string[];
  width?: number;
}

const MediaPreview: React.FC<MediaPreviewProps> = (props) => {
  const [open, setOpen] = useState(false);
  const urls = props.urls ? props.urls.filter((i) => i) : [];

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showModal}>查看({urls.length})</Button>
      <Modal
        open={open}
        title="查看图片"
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
      >
        <div className="flex gap-1">
          {urls.map((url, index) => (
            <Image
              width={200}
              height={200}
              key={index}
              src={imageUrl(url)}
              style={{
                objectFit: "contain",
                border: "1px solid #ccc",
                backgroundColor: "#ccc",
              }}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default MediaPreview;
