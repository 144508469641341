import { routerInfo } from "@/router/router-info";
import type { MenuProps } from "antd";
import { Button, Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

// 根据items生成
const items: MenuItem[] = routerInfo.map((item, index) => {
  return {
    key: index + "",
    label: <Link to={item.path}>{item.title}</Link>,
  };
});

const WebNav: React.FC = () => {
  const [current, setCurrent] = useState("0");

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  return (
    <div className="flex h-screen w-44 flex-col border-r">
      <h1 className="border-b py-3 text-center text-lg font-bold">管理系统</h1>
      <div className="flex-grow-1 overflow-y-auto">
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          // mode="horizontal"
          items={items}
        />
      </div>

      <div className="mt-auto flex items-center justify-center border-t py-5">
        <Button
          type="primary"
          onClick={() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/login";
          }}
        >
          退出登录
        </Button>
      </div>
    </div>
  );
};

export default WebNav;
