// src/pages/VouchersPage.tsx
import DynamicTable from "@/components/DynamicTable";
import MediaPreview from "@/components/MediaPreview";
import MyUpload from "@/components/upload";
import { PRODUCT_TITLE } from "@/config";
import useOptions from "@/hooks/useOptions";
import { Input, Select } from "antd";
import React from "react";

// 列定义
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
  },
  {
    title: `${PRODUCT_TITLE}`,
    dataIndex: ["products_swiper_images_product_idToproducts", "title"],
    key: "product",
  },
  {
    title: "图片",
    dataIndex: "media",
    key: "media",
    render: (media: any) => <MediaPreview urls={[media?.url]} />,
  },
];

const VouchersPage: React.FC = () => {
  const products = useOptions("/products"); // 代金券列表
  // 表单项定义
  const formItems = () => {
    return [
      {
        name: "title",
        label: "标题",
        rules: [{ required: true, message: "请输入标题!" }],
        component: <Input style={{ width: "100%" }} />,
      },
      {
        name: "product_id",
        label: `${PRODUCT_TITLE}`,
        component: (
          <Select
            placeholder={`选择${PRODUCT_TITLE}`}
            style={{ width: "100%" }}
          >
            {products.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        name: "image",
        label: "图片",
        render: (value: any) => (
          <MyUpload maxCount={1} medias={[value?.media].filter((i) => i)} />
        ),
      },
    ];
  };
  return (
    <DynamicTable
      columns={columns}
      dataSourceUrl="/swiper_images"
      createUrl="/swiper_images"
      updateUrl="/swiper_images"
      deleteUrl="/swiper_images"
      formItems={formItems()}
      title="首页轮播图管理"
    />
  );
};

export default VouchersPage;
